import * as _lib2 from "csv-generate/lib";

var _lib = "default" in _lib2 ? _lib2.default : _lib2;

import * as _lib4 from "csv-parse/lib";

var _lib3 = "default" in _lib4 ? _lib4.default : _lib4;

import * as _lib6 from "stream-transform/lib";

var _lib5 = "default" in _lib6 ? _lib6.default : _lib6;

import * as _lib8 from "csv-stringify/lib";

var _lib7 = "default" in _lib8 ? _lib8.default : _lib8;

var exports = {};
// Alias to the ES6 modules exposing the stream and callback APIs
exports = {
  generate: _lib,
  parse: _lib3,
  transform: _lib5,
  stringify: _lib7
};
export default exports;
export const generate = exports.generate;